<template>
  <div class="connections-table">
    <PreviewSpiner v-if="!isReady" />
    <div v-if="roundsCount">
      <div class="connections-table_round-number">
        <div v-for="(roundNumber) in roundsCount" :key="roundNumber" class="connections-table_round-label">
          Round: {{ roundNumber }}
        </div>
      </div>
      <div class="connections-table_rounds" @click="handleActions($event)" ref="rounds">
        <div v-for="(roundNumber) in roundsCount" :key="roundNumber" class="connections-table_round">
          <div v-if="Object.keys(contestsByRounds[roundNumber]).length" class="connections-table_round-block">
            <div
              v-for="(item) in contestsByRounds[roundNumber]"
              :key="item.id"
              class="connections-table_round-item"
              :class="[{hasEntrants: item.entrantsFromContest && item.entrantsFromContest.length}, item.status.toLowerCase()]"
              :data-connect-id="item.id"
            >
              <div :data-contest-id="item.id" class="connections-table_round-item-name">
                {{ item.name }}
              </div>
              <div class="connections-table_round-item-description" v-html="getItemDescription(item)"></div>
              <div class="connections-table_round-item-actions" :data-actions-id="item.id"></div>
              <div class="pt-0 dropdown-menu" data-popper-placement="bottom-end" :data-dropdown-id="item.id">
                <a
                  role="menuitem"
                  target="_self"
                  class="dropdown-item p-0"
                  v-for="action in getDropdownActions(item)" :key="action.handler"
                >
                  <button
                    type="button"
                    class="btn action-custom-button btn-ghost-"
                    :data-action-handler="action.handler"
                    :data-action-id="item.id"
                  >
                    {{ action.title }}
                  </button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Modal
      :modalShow="isShowModal"
      :messageGeneral="messageGeneral"
      :title="modalTitle"
      @doFunction="deleteSelectedField"
      v-on:toggle-modal="closeModal()"
      :isSuccessButton="true"
    />
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { cloneDeep } from 'lodash';
import { contestsTexts } from "@/config/pageTexts/contests.json";
import Modal from '@/shared/UI/Modal';
import PreviewSpiner from "@/shared/UI/Spiner";
export default {
  name: 'ConnectionsTable',
  components: {
    Modal,
    PreviewSpiner,
  },
  data() {
    return {
      isReady: false,
      isShowModal: false,
      selectedId: null,
      messageGeneral: '',
      modalTitle: '',
      contests: [],
      contestsIds: [],
      contestsEntrantsMap: {},
      texts: {
        ...contestsTexts,
      },
      roundsCount: 0,
      contestsByRounds: {},
      elementPairs: [],
      dropDownActions: [
        {
          title: this.$t('buttons.preview'),
          handler: 'showPreview',
          disabled: false,
        },
        {
          title: this.$t('buttons.edit'),
          handler: 'editRecord',
          disabled: false,
        },
        {
          title: this.$t('buttons.delete'),
          handler: 'handleDeleteModal',
          disabled: false,
        },
      ],
      activeActions: [
        {
          title: 'Finish',
          handler: 'setFinishedStatus',
        },
        {
          title: 'Cancel',
          handler: 'setCancelStatus',
        }
      ],
      readyActions: [
        {
          title: 'Start',
          handler: 'setActiveStatus',
        },
        {
          title: 'Cancel',
          handler: 'setCancelStatus',
        }
      ],
    }
  },
  async created() {
    await this.initialize();
  },
  beforeDestroy() {
    document.removeEventListener('click', this.clickListener, false);
  },
  methods: {
    ...mapActions('contests', ['handleGetContestsByQuery', 'handleUpdateContestsState']),
    ...mapActions('entrants', ['handleGetEntrantsByQuery']),
    async initialize() {
      await this.getContestsByCompetition();
      await this.getEntrantsByContests();
      this.setRoundsCount();
      this.setContestsByRounds();
      this.setElementPairs();
    },
    async getContestsByCompetition() {
      this.contests = await this.handleGetContestsByQuery({
        queryRequest: {
          must: [
            {
              "queryField": "competitionId",
              "queryValues": [
                this.$route.params.id
              ]
            }
          ],
          sortBy: [
            {
              queryField: 'round',
              order: 'Desc',
            },
          ],
          skip: 0,
          limit: 999,
        }
      });
    },
    async getEntrantsByContests() {
      if (this.contests && this.contests.length) {

        for (let i = 0; i < this.contests.length; i++) {
          const entrantsMeta = await this.handleGetEntrantsByQuery({
            queryRequest: {
              must: [
                {
                  queryField: 'participationId',
                  queryValues: [this.contests[i].id]
                }
              ],
              sortBy: [
                {
                  queryField: 'timestamp',
                  order: 'Desc'
                }
              ],
              skip: 0,
              limit: 0
            }
          });

          this.contestsEntrantsMap[this.contests[i].id] = entrantsMeta.totalRecords;
        }
      }
    },
    setRoundsCount() {
      if (this.contests.length) {
        this.roundsCount = this.contests[0].round;
      }
    },
    setContestsByRounds() {
      this.contestsByRounds = {};
      if (this.contests.length) {
        for (let i = 1; i <= this.roundsCount; i++) {
          this.contestsByRounds[i] = this.contests.filter(c => c.round === i);
        }
      }
    },
    setElementPairs() {
      if (this.contests.length) {
        this.elementPairs = [];
        this.contests.forEach(c => {
          if (c.entrantsFromContest && c.entrantsFromContest.length) {
            c.entrantsFromContest.forEach(e => this.elementPairs.push([c.id, e]));
          }
        });
        this.elementPairs = [...new Set(this.elementPairs)];
      }

      this.sortContests();

      this.isReady = true;

      this.$nextTick(() => {
        this.drawConnectionLines();
      })
    },
    sortContests() {
      let sorted = {};
      if (Object.keys(this.contestsByRounds).length && this.elementPairs.length) {
        sorted[this.roundsCount] = cloneDeep(this.contestsByRounds[this.roundsCount]);
        let tmp = cloneDeep(this.contestsByRounds);
        for (let round = this.roundsCount - 1; round > 0; round--) {
          if (!sorted[round]) sorted[round] = cloneDeep([]);
          this.contestsByRounds[round + 1].forEach(contest => {
            if (contest.entrantsFromContest && contest.entrantsFromContest.length) {
              contest.entrantsFromContest.forEach(entrant => {
                let idx = tmp[round].findIndex(c => c.id === entrant)
                if (idx !== -1) {
                  let el = tmp[round].splice(idx, 1);
                  sorted[round].push(el[0]);
                }
              })
            }

            this.contestsByRounds[round] = cloneDeep(sorted[round]);
          })
        }

        for (let round = 1; round < this.roundsCount; round++) {
          if (tmp[round] && tmp[round].length) {
            tmp[round].forEach(c => {
              this.contestsByRounds[round].push(c);
            });
          }
        }
      }
    },
    drawConnectionLines() {
      let coordinatesArr = [];
      if (this.elementPairs.length) {
        this.elementPairs.forEach(pair => {
          let firsPoint = document.querySelector(`[data-connect-id="${pair[0]}"]`);
          let firsPointCoordinates = {left: firsPoint.offsetLeft, top: firsPoint.offsetTop + 20};
          let secondPoint = document.querySelector(`[data-connect-id="${pair[1]}"]`);
          let secondPointCoordinates = {left: secondPoint.offsetLeft + 150, top: secondPoint.offsetTop + 20}
          coordinatesArr.push([firsPointCoordinates, secondPointCoordinates, /*pair*/]);
        })
      }
      if (coordinatesArr.length) {
        coordinatesArr.forEach(c => this.drawLine(c[0].left, c[0].top, c[1].left, c[1].top, /*c[2]*/));
      }
    },
    drawLine(x1, y1, x2, y2, /*pair*/) {
      // const contest = this.contests.find(c => c.id === pair[1]);
      if (x2 < x1) {
        let tmp;
        tmp = x2 ; x2 = x1 ; x1 = tmp;
        tmp = y2 ; y2 = y1 ; y1 = tmp;
      }
      let container = document.querySelector('.connections-table_rounds');
      let width =  Math.abs(x2 - x1) / 2;
      let height =  Math.abs(y2 - y1);
      let border = 'border-top: solid 1px #ddd';
      let lineClass = 'top-line';
      if (y1 > y2) {
        lineClass = 'bottom-line'
        y1 = y2;
        border = 'border-bottom: solid 1px #ddd';
      }

      // let description = '';
      // if (contest.description) {
      //   description = "<div class='bracket-description'>" + contest.description + "</div>";
      // }

      container.innerHTML += "<div class='bracket " + lineClass + "' style='width: " + width + "px; height: " + height + "px; " + border + "; border-right: solid 1px #ddd; position: absolute; top: " + y1 + "px; left: " + x1 + "px;'></div>";

    },
    getItemDescription(item) {
      const status = item.status;
      const startDate = item.actualStartDate ?? item.scheduledStartDate;
      const endDate = item.actualEndDate ?? item.scheduledEndDate;
      const entrants = this.contestsEntrantsMap[item.id] ?? 0;

      const statusElement = "<h5>" + status + "</h5>";
      const startDateElement = `<p><b>Start Date: </b>${startDate}</p>`;
      const endDateElement = `<p><b>End Date: </b>${endDate}</p>`;
      const entrantsElement = `<p><b>Entrants: </b>${entrants}</p>`;

      return(statusElement + startDateElement + endDateElement + entrantsElement);
    },
    handleActions(event) {
      if (event.target.dataset.contestId) {
        this.showPreview(event.target.dataset.contestId);
      }
      if (event.target.dataset.actionsId) {
        this.openDropDown(event.target.dataset.actionsId)
      }
      if (event.target.dataset.actionHandler) {
        const id = event.target.dataset.actionId;
        switch (event.target.dataset.actionHandler) {
          case 'showPreview':
            this.showPreview(id);
            break;
          case 'editRecord':
            this.editRecord(id);
            break;
          case 'handleDeleteModal':
            this.handleDeleteModal(id);
            break;
          case 'setFinishedStatus':
            this.setFinishedStatus(id);
            break;
          case 'setCancelStatus':
            this.setCancelStatus(id);
            break;
          case 'setActiveStatus':
            this.setActiveStatus(id);
            break;
        }
      }
    },
    editRecord(id) {
      if (id) {
        this.$router.push({
          name: 'EditContest',
          params: {
            id: id
          },
        });
      }
    },
    handleDeleteModal(id) {
      this.hideDropDowns();
      this.isShowModal = true;
      this.selectedId = id;
      this.messageGeneral = this.texts.deleteMessage;
      this.modalTitle = this.texts.deleteTitle;
    },
    async deleteSelectedField() {
      this.isReady = false;
      this.isShowModal = false;
      const updateContestStateRequestArray = [{ contestId: this.selectedId, status: 'Deleted' }];
      await this.handleUpdateContestsState({ updateContestStateRequestArray });

      setTimeout(() => {
        this.$emit('reload');
      }, 4000);
    },
    async setFinishedStatus(id) {
      this.hideDropDowns();
      this.isReady = false;
      const updateContestStateRequestArray = [{ contestId: id, status: 'Finished' }];
      await this.handleUpdateContestsState({ updateContestStateRequestArray });

      setTimeout(() => {
        this.$emit('reload');
      }, 4000);
    },
    async setCancelStatus(id) {
      this.hideDropDowns();
      this.isReady = false;
      const updateContestStateRequestArray = [{ contestId: id, status: 'Cancelled' }];
      await this.handleUpdateContestsState({ updateContestStateRequestArray });

      setTimeout(() => {
        this.$emit('reload');
      }, 4000);
    },
    async setActiveStatus(id) {
      this.hideDropDowns();
      this.isReady = false;
      const updateContestStateRequestArray = [{ contestId: id, status: 'Active' }];
      await this.handleUpdateContestsState({ updateContestStateRequestArray });

      setTimeout(() => {
        this.$emit('reload');
      }, 4000);
    },
    closeModal() {
      this.isShowModal = false;
    },
    showPreview(id) {
      if (id) {
        this.$router.push({
          name: 'PreviewContest',
          params: {
            id: this.$route.params.id,
            contestId: id
          },
        });
      }
    },
    getDropdownActions(item) {
      if (typeof item === 'object') {
        switch (item.status) {
          case 'Ready':
            return [...this.dropDownActions, ...this.readyActions];
          case 'Active':
            return [...this.dropDownActions, ...this.activeActions];
        }
      }
      return this.dropDownActions;
    },
    openDropDown(id) {
      const dropdownEl = this.$refs.rounds.querySelector(`[data-dropdown-id='${id}']`);
      if (dropdownEl) {
        if (dropdownEl.classList.contains('show')) {
          this.hideDropDowns();
        } else {
          this.hideDropDowns();
          dropdownEl.classList.add('show');
        }
      }
    },
    hideDropDowns() {
      const dropDowns = this.$refs.rounds.getElementsByClassName('dropdown-menu');
      if (dropDowns && dropDowns.length) {
        dropDowns.forEach(d => {
          if (d.classList.contains('show')) {
            d.classList.remove('show');
          }
        })
      }
    },
    clickListener(e) {
      if (!e.target.closest('.connections-table_round-item-actions') && !e.target.closest('.dropdown-menu')) {
        this.hideDropDowns();
      }
    }
  },
  watch: {
    isReady(val) {
      if (val) {
        document.addEventListener('click', this.clickListener, false);
      }
    }
  }
}
</script>

<style lang="scss">
.card-body {
  overflow: auto;
}
.connections-table {
  padding-bottom: 180px;
  &_rounds {
    display: flex;
    position: relative;
    // align-items: center;
  }
  &_round {
    &-number {
      display: flex;
    }
    &-label {
      width: 150px;
      min-width: 150px;
      text-align: center;
      padding: 5px 0;
      margin: 0 25px 20px;
      font-size: 14px;
      font-weight: 600;
      color: #01a3bb;
      background-color: #e8f8fa;
      border-radius: 12px;
    }
    &-block {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      height: 100%;
    }
    &-item {
      display: flex;
      justify-content: space-between;
      position: relative;
      width: 150px;
      padding: 10px 15px;
      margin: 0 25px 20px;
      text-align: center;
      background-color: #cccccc;
      border-radius: 12px;
      font-size: 14px;
      font-weight: 600;
      color: #282f37;
      cursor: pointer;
      &-description {
        display: none;
        background-color: #505a66;
        border-radius: 12px;
        border: none;
        padding: 8px 8px;
        color: #fff;
        font-size: 12px;
        width: 200px;
        position: absolute;
        text-align: left;
        font-weight: normal;
        left: 156px;
        top: -75px;
        z-index: 10;
      }
      //&:hover {
      //  .connections-table_round-item-description {
      //    display: block;
      //  }
      //}
      &-name {
        cursor: pointer;
        width: 100%;
        &:hover {
          & + .connections-table_round-item-description {
            display: block;
          }
        }
      }
      &-actions {
        &:after {
          content: '\2807';
          font-size: 15px;
          cursor: pointer;
        }
      }
      &.hasEntrants {
        &:before {
          content: '';
          display: block;
          position: absolute;
          left: -25px;
          top: 20px;
          width: 25px;
          height: 1px;
          background-color: #ddd;
        }
      }
      &.active {
        background-color: #bfe8ee;
      }
      &.ready {
        background-color: #d7f1d1;
      }
      &.finalised {
        background-color: #fcedce;
      }
      &.cancelled {
        background-color: #f9d2cd;
      }
    }
  }
  .dropdown-menu {
    min-width: 7rem;
    position: absolute;
    inset: 0 auto auto 0;
    margin: 0;
    transform: translate(71px, 38px);;
    &.show {
      &:before {
        content: '';
        width: 15px;
        height: 15px;
        position: absolute;
        top: -10px;
        left: 50%;
        transform: translate(-50%, 5px) rotate(45deg);
        background: #505a66;
        z-index: -1;
      }
    }
  }
  //.bracket {
  //  cursor: pointer;
  //  &-description {
  //    display: none;
  //    background-color: #505a66;
  //    border-radius: 12px;
  //    border: none;
  //    padding: 8px 8px;
  //    color: #fff;
  //    font-size: 12px;
  //    width: 150px;
  //    position: absolute;
  //    left: 30px;
  //    top: -10px;
  //    z-index: 10;
  //    h3 {
  //      font-size: 12px;
  //    }
  //  }
  //  &:hover {
  //    border-color: #3a99ff !important;
  //    .bracket-description {
  //      display: block;
  //    }
  //  }
  //}
}
</style>