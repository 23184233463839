<template>
  <div class="preview">
    <Alert :message="message" :success="true"/>
    <!--  HEADER  -->
    <div class="zq-page-title-wrapper d-flex">
      <h3 class="zq-page-title">{{ texts.previewCompetition.title }}</h3>
      <IconWithTooltip class="zq--header-tooltip" :text="descriptions.pageTitle"/>
    </div>
    <CTabs class="zq--tabs" @update:activeTab="updateActiveTab" ref="competitionTabs">
      <CTab :title="texts.previewCompetition.tabs.overview">
        <CompetitionDetails
          @competitionName="setCompetitionName"
          @updateActiveTab="updateActiveTab"
          @setCompetitionStatus="setCompetitionStatus"
        />
      </CTab>
      <CTab :title="texts.previewCompetition.tabs.contests">
        <PreviewContests v-if="isContests" :key="previewContestsKey"/>
      </CTab>
      <CTab :title="texts.previewCompetition.tabs.entrants">
        <CompetitionEntrantsDetails v-if="isEntrants" :entityId="$route.params.id"/>
      </CTab>
      <CTab :title="texts.previewCompetition.tabs.awards" v-if="competitionStatus === 'Finalised'">
        <AwardsDetails v-if="isAwards" :entityType="'Competition'" />
      </CTab>
    </CTabs>
  </div>
</template>

<script>
import CompetitionDetails from './CompetitionDetails';
import PreviewContests from '@/views/ziqni/competitions/Preview/details/ContestsDetail';
import IconWithTooltip from '@/shared/UI/IconWithTooltip';
import { competitions } from '@/config/descriptions/competitions';
import { competitionsTexts } from '@/config/pageTexts/competitions.json';
import routerBreadcrumbs from '@/router/breadcrumb/routerBreadcrumbs';
import CompetitionEntrantsDetails from '@/shared/components/supportModels/entrants/EntityEntrants';
import Alert from '@/components/Alert';
import AwardsDetails from '@/views/ziqni/achievements/AwardsDetails';

export default {
  components: {
    AwardsDetails,
    CompetitionEntrantsDetails,
    CompetitionDetails,
    PreviewContests,
    // EntrantsTable,
    IconWithTooltip,
    Alert
  },
  data() {
    return {
      texts: {
        ...competitionsTexts
      },
      isContests: false,
      isEntrants: false,
      isLeaderboard: false,
      isAwards: false,
      previewContestsKey: 0,
      tabsMap: {
        0: 'isCompetition',
        1: 'isContests',
        2: 'isEntrants',
        3: 'isAwards',
      },
      competitionName: '',
      competitionStatus: '',
      message: null,
    }
  },
  computed: {
    descriptions() {
      return {
        ...competitions.preview
      }
    }
  },
  mounted() {
    if (this.$route.query && this.$route.query.isContest) {
      this.$refs.competitionTabs.activeTabIndex = 1;
      this.isContests = true;
    }
    if (this.$route.query && this.$route.query.contestId) {
      this.$refs.competitionTabs.activeTabIndex = 1;
      this.isContests = true;
    }
    if (this.$route.params.showMessage) {
      setTimeout(() => {
        this.message = this.texts.previewCompetition.congratulations;
      }, 6 * 1000);
    }
  },
  methods: {
    setCompetitionName(val) {
      if (val) this.competitionName = val;
    },
    setCompetitionStatus(status) {
      this.competitionStatus = status;
    },
    updateActiveTab(val) {
      if (this.competitionName) {
        routerBreadcrumbs(this.$router.currentRoute, {
          name: this.competitionName
        });
      }
      switch (this.tabsMap[val]) {
        case 'isCompetition':
          this.previewContestsKey++;
          break;
        case 'isContests': {
          this.isContests = true;
          this.$refs.competitionTabs.activeTabIndex = 1;
          break;
        }
        case 'isEntrants':
          this.isEntrants = true;
          break;
        case 'isLeaderboard':
          this.isLeaderboard = true;
          break;
        case 'isAwards':
          this.isAwards = true;
          break;
      }
    }
  },
}
</script>

<style lang="scss">
.preview {
  display: flex;
  flex-direction: column;
  height: 100%;
}
</style>
